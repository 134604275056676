const Project = (props) => {

    console.log(props.img)
    return(
        <a className="Project-link" href={props.link}><div className="Project">
            <p className="Project-title">{props.title}</p>
            <img className="Project-img" src={props.img} alt={props.alt} />
            <p className="Project-desc">{props.desc}</p>
        </div></a>
    )
}

export default Project;