import React, {useEffect} from "react";
import Next from "../components/images/next-arrow.png";
import Project from "../components/Project";
import Jazzed from "../components/images/jazzed.png";
import Portfolio from "../components/images/portfolio.png";
import Noetikon from "../components/images/noetikon.png";
import Pazaak from "../components/images/pazaak.png";

const ProjectGallery = () => {

    let projects = [
        {
            title: "Spotify Jazzed",
            img: Jazzed,
            alt: "Spotify Jazzed",
            link: "https://github.com/codyddalton/spotifyJazzed_v2",
            desc: "RESTful API, Spotify Web API, Search Interface"
        },
        {
            title: "CODYDDALTON.COM",
            img: Portfolio,
            alt: "My Digital Portfolio",
            link: "https://github.com/codyddalton/my-digital-portfolio",
            desc: "Digital Portfolio, React, Responsive Design"
        },
        {
            title: "The Noetikon",
            img: Noetikon,
            alt:"The Noetikon",
            link:"https://github.com/codyddalton/thenoetikon",
            desc: "Quote Bank, Full-CRUD, Search Interface"
        },
        {
            title: "Pazaak Attaak",
            img: Pazaak,
            link:"https://github.com/codyddalton/pazaakAttaak",
            desc: "Browser Game, React, Multiplayer"
        }
    ]

    window.onload = () => {
        if(document.getElementById("Gallery-container").scrollLeft === 0){
            document.getElementById("Back-butn").disabled = true;
        }
        if(document.getElementById("Gallery-container").scrollLeft === 1256){
            document.getElementById("Next-butn").disabled = true;
        }
    }
   
    

    return(
        <div className="Project-gallery">
            <button id="Back-butn" onClick={(() => {
                document.getElementById("Next-butn").disabled = false;
                if(document.getElementById("Gallery-container").scrollLeft < 301){
                    document.getElementById("Back-butn").disabled = true;
                }
                document.getElementById("Gallery-container").scrollLeft += -300;
            })}><img src={Next} alt="Back" className="Back-button" /></button>
            <div id="Gallery-container" className="Gallery-container">
                {projects.map((n) =>
                    <Project 
                        title={n.title}
                        img={n.img}
                        alt={n.alt}
                        link={n.link}
                        desc={n.desc}
                    />
                )}
            </div>
            <button id="Next-butn" onClick={(() => {
                console.log(document.getElementById("Gallery-container").scrollLeft);
                if(document.getElementById("Gallery-container").scrollLeft > 425){
                    document.getElementById("Next-butn").disabled = true;
                }
                document.getElementById("Back-butn").disabled = false;
                document.getElementById("Gallery-container").scrollLeft += 300;
            })}><img src={Next} alt="Next" className="Next-button" /></button>
        </div>
    )
}
export default ProjectGallery;